<template>
  <div class="depositConfirm">
     <div class="bg"></div>
     <div class="content">
             <headbox title="交押金" :isbackPath="true"  path="pricingDeposit"  bgColor="#39394d"></headbox>
             <div class="topBg"></div>
             <div class="boxTips">
<!--                    <div class="tipIcon">
                    </div>-->
               <div class="tipText">
                 <h2>提交成功</h2>
                 <!-- <p>订单号：XXXXXXXXXXXX</p> -->
               </div>
               <div style="margin-top: 280px">
                 <div class="seeBtn btn" @click="seeOrder">查看订单</div>
                 <div class="backBtn btn" @click="back">返回首页</div>
               </div>

             </div>
     </div>
  </div>
</template>

<script>
import headbox from '@/components/head.vue'
export default {
    components:{
        headbox
    },
    data() {
        return {
            
        }
    },
    methods:{
        back(){
         this.$router.push({
                   name:'person'
            })
        },
        seeOrder(){
            this.$router.push({
                   name:'pricingDeposit'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.topBg {
  position: relative;
  height: 824px;
  width: 702px;
  left: 24px;
  background: $YellowDepositSuccessBg;
  background-size: 100% 100%;
  top: 24px;
}
.boxTips{
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    //padding: 0px 20px;
  text-align: center;
    .tipIcon{
            height: 80px;
            width: 80px;
            background: url('~@/assets/images/success_black@2x.png')no-repeat;
            background-size: 100%;
            //padding-top:280px ;
      margin: 48px 335px 16px;

    }
  .tipText{
    width: 400px;
    margin: 300px auto;
    text-align: center;
    h2{
      color: #242424;
      font-weight: 500;
      font-size: 36px;
    }
    p{
      color: #999999;
      font-size: 28px;
      margin-top:12px;
    }
  }
    .btn{
        border-radius: 50px;
        text-align: center;
        color: #fff;
        height: 86px;
        line-height: 86px;
        font-size: 32px;
      width: 686px;
      margin: 40px 32px 0;
    }
    .seeBtn{
      background: $YellowFontColor;
      color: $YellowHeadBackgroundColor;
    }
    .backBtn{
      //background: #F6D0AB;
      color: $YellowBarColor;
        border: 1px solid $YellowDepositTuiColor;
    }
}
.bg{
  background: #F1F3F7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.content{
    position: relative;
    z-index: 1;
  text-align: center;
}
</style>