var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "depositConfirm" }, [
    _c("div", { staticClass: "bg" }),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("headbox", {
          attrs: {
            title: "交押金",
            isbackPath: true,
            path: "pricingDeposit",
            bgColor: "#39394d"
          }
        }),
        _c("div", { staticClass: "topBg" }),
        _c("div", { staticClass: "boxTips" }, [
          _vm._m(0),
          _c("div", { staticStyle: { "margin-top": "280px" } }, [
            _c(
              "div",
              { staticClass: "seeBtn btn", on: { click: _vm.seeOrder } },
              [_vm._v("查看订单")]
            ),
            _c("div", { staticClass: "backBtn btn", on: { click: _vm.back } }, [
              _vm._v("返回首页")
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tipText" }, [
      _c("h2", [_vm._v("提交成功")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }