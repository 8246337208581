// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/newImages/pic_deposit_success_yellow@2x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/success_black@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".topBg[data-v-871fdde2]{position:relative;height:109.867vw;width:93.6vw;left:3.2vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;top:3.2vw}.boxTips[data-v-871fdde2]{width:100%;position:absolute;top:13.333vw;left:0;text-align:center}.boxTips .tipIcon[data-v-871fdde2]{height:10.667vw;width:10.667vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100%;margin:6.4vw 44.667vw 2.133vw}.boxTips .tipText[data-v-871fdde2]{width:53.333vw;margin:40vw auto;text-align:center}.boxTips .tipText h2[data-v-871fdde2]{color:#242424;font-weight:500;font-size:4.8vw}.boxTips .tipText p[data-v-871fdde2]{color:#999;font-size:3.733vw;margin-top:1.6vw}.boxTips .btn[data-v-871fdde2]{border-radius:6.667vw;text-align:center;color:#fff;height:11.467vw;line-height:11.467vw;font-size:4.267vw;width:91.467vw;margin:5.333vw 4.267vw 0}.boxTips .seeBtn[data-v-871fdde2]{background:#dca765;color:#fff}.boxTips .backBtn[data-v-871fdde2]{color:#704208;border:1px solid #f6e3c2}.bg[data-v-871fdde2]{background:#f1f3f7;width:100%;height:100%;position:fixed;top:0;left:0;z-index:1}.content[data-v-871fdde2]{position:relative;z-index:1;text-align:center}", ""]);
// Exports
module.exports = exports;
